import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import TableContainer from "./customerOustandingReportListContainer"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Spinners from "components/Common/Spinner"
import {
  getConsumptionFilter,
  getReportForCustomerOustanding,
} from "store/actions"
import formatNumberWithCommas from "components/Common/CommaSeparator"

const CustomerOustandingReport = () => {
  const navigate = useNavigate()
  const [permission, setPermission] = useState(
    JSON.parse(localStorage.getItem("permission"))
  )
  document.title = "Consumption Outstanding Report"
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [clientId, setClientId] = useState(authUser ? authUser.id : "")
  const dispatch = useDispatch()
  const customerOutstandingReport = state =>
    state.oustandingReports.outstandingReport.data
  const customerOutstandingReportList =
    useSelector(customerOutstandingReport) || []
  const consumptionTotalState = state =>
    state.consumptionReport?.Consumption?.total_sum
  const consumptionTotal = useSelector(consumptionTotalState) || []
  const loadingState = state => state.consumptionReport.loading
  const loading = useSelector(loadingState) || []

  const [isLoading, setLoading] = useState(loading)

  useEffect(() => {
    const value = {}
    dispatch(getReportForCustomerOustanding(value))
  }, [dispatch])

  const location = useLocation()

  const [projectId2, setProjectId] = useState(null)
  const [companyId2, setCompanyId] = useState(null)
  const [customerType2, setCustomerType] = useState(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const project = searchParams.get("project")
    const company = searchParams.get("company")
    const customer = searchParams.get("customer")

    setProjectId(project)
    setCompanyId(company)
    setCustomerType(customer)

 
  }, [location])

  const [modalLarge, setModalLarge] = useState(false)

  const toggleModalLarge = () => setModalLarge(!modalLarge)
  const [billingId, setBillingId] = useState()

  const handleBillPreview = id => {
    setBillingId(id)
    toggleModalLarge()
  }

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "srNo",
        sortBy: true,
        // filterable: false,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">{cellProps.row.index + 1}</h5>
            </>
          )
        },
      },

     
      {
        Header: "Customer",
        accessor: "client_name",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to={`/clients/view-client/${cellProps.row.original.customer_id}`}
                >
                  {cellProps.row.original.client_name}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Opening Balance",
        accessor: "opening_balance",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                <Link
                  className="text-dark"
                  to={`/clients/view-client/${cellProps.row.original.customer_id}`}
                >
                  {cellProps.row.original.opening_balance}
                </Link>
              </h5>
            </>
          )
        },
      },
      {
        Header: "Total Billing",
        accessor: "billing_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.billing_amount}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Payment Received ",
        accessor: "receipt_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.receipt_amount}
              </h5>
            </>
          )
        },
      },
      {
        Header: "TDS Deducted",
        accessor: "tds_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.tds_amount}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Total Outstanding",
        accessor: "outstanding_amount",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.outstanding_amount}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          const projectId = cellProps.row.original.project_id
          const companyId = cellProps.row.original.company_id
          const clientId = cellProps.row.original.customer_id
          const financialYear = cellProps.row.original.financial_year
          const customerType1 = cellProps.type
          const projectId1 = cellProps.projectId2
          const companyId1 = cellProps.companyId2
          return (
            <div className="d-flex gap-3">
              {permission
                .filter(
                  item => item.page === "view-ledger/:id?" && item.access === 1
                )
                .map(index => (
                  <Link
                    key={index}
                    to={`/view-ledger/${clientId}`}
                    className="text-primary"
                  >
                    View Ledger
                    <i
                      className="mdi mdi-chevron-right font-size-18"
                      id="viewtooltip"
                      style={{ fontSize: "18px", color: "#3366cc" }}
                    />
                    <UncontrolledTooltip
                      placement="bottom"
                      target="viewtooltip"
                    >
                      View Ledger
                    </UncontrolledTooltip>
                  </Link>
                ))}
            </div>
          )
        },
      },
    ],
    []
  )

  const handleUserClick = arg => {
    const user = arg
  }

  var node = useRef()
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const onClickDelete = users => {
    setDeleteId({ id: users })
    setDeleteModal(true)
  }

  const handleDeleteUser = () => {
    if (deleteId && deleteId.id) {
      dispatch(deletePendingBills(deleteId.id))
    }
    onPaginationPageChange(1)
    setDeleteModal(false)
    navigate("/pendingBills")
    dispatch(getPendingBillsList())
  }

  const handleUserClicks = () => {
    setUserList("")
    toggle()
  }

  const keyField = "id"

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Report"
            breadcrumbItem="Customer Outstanding Report"
          />

          <Row>
            {isLoading ? (
              <Spinners setLoading={setLoading} />
            ) : (
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      isPagination={true}
                      columns={columns}
                      data={customerOutstandingReportList || []}
                      type={customerType2}
                      projectId2={projectId2}
                      companyId2={companyId2}
                      isGlobalFilter={true}
                      isShowingPageLength={true}
                      isAddUserList={true}
                      iscustomPageSizeOptions={true}
                      handleUserClick={handleUserClicks}
                      customPageSize={10}
                      tableClass="table align-middle table-nowrap table-hover"
                      theadClass="table-light"
                      paginationDiv="col-sm-12 col-md-7"
                      pagination="pagination pagination-rounded justify-content-end mt-4"
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomerOustandingReport
